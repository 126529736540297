<template>
  <div class="project-body">
    <Navbar />
    <div class="project">
      <h1>Site en PHP</h1>
      <p>
        Projet réalisé à l'aide de PHP et de phpMyAdmin (base de données).
      </p>
      <p>
        Dans quel cadre ? : Digital Campus
      </p>
    </div>
    <div class="container-project">
      <img
        class="first"
        src="@/assets/images/portfolio/ecolidaire/ecolidaire-2.png"
        data-aos="zoom-in"
        data-aos-duration="1000"
      />
      <p>
        L'objectif de ce site est de suivre les projets selon les catégories,
        mais également de voir les membres sont concernés, le coût, etc...<br />
      </p>
    </div>
    <div class="container-project1">
      <p>
        La gestion se fait à partir d'un tableau de bord, afin de faciliter le
        traitement des projets.
      </p>
      <img
        class="second"
        src="@/assets/images/portfolio/ecolidaire/ecolidaire-1.png"
        data-aos="zoom-in"
        data-aos-duration="1000"
      />
    </div>
    <p>
      <router-link
        class="btn btn-dark mt-2 float-lg-center mb-lg-5 btn-sm"
        :to="{ name: 'Portfolio' }"
      >
        <fontAwesomeIcon :icon="['fas', 'arrow-left']" class=" menuColor" />
        Retour vers le portfolio
      </router-link>
    </p>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Refonte",
  components: { Navbar, Footer }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/projetView.scss";
</style>
